import React from "react";

function WeGotYou() {
  return (
    <section id="we-got-you" className="bg-gray-50 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl space-y-12 px-4 py-8 lg:space-y-20 lg:px-6 lg:py-24">
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              We've got your back 🧰
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Deliver great service experiences fast - without the complexity of
              traditional ITSM solutions. Accelerate critical development work,
              eliminate toil, and deploy changes with ease.
            </p>
            <ul className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700">
              <li className="flex space-x-3">
                <span>📱</span>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Digital Product Development
                    <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  Mobile apps, web applications, and user engagement
                  </span>         
                </span>
              </li>
              <li className="flex space-x-3">
                <span>🎨</span>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Branding & Identity Design
                  <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  Crafting unique visual identities that stand out</span>
                  </span>
              </li>
              <li className="flex space-x-3">
                 <span>🔓</span>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Open-Source Solutions
                  <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  Scalable, flexible, and cost-effective platforms and technologies
                  </span>
                </span>
              </li>
              <li className="flex space-x-3">
                <span>🛍️</span>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  E-commerce & Online Platforms
                  <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  User-friendly, secure, and visually appealing stores
                  </span>
                </span>
              </li>
              <li className="flex space-x-3">
                <span>🚀</span>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">Digital Marketing & SEO
                  <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  Data-driven campaigns for traffic, conversions, and loyalty
                  </span>
                </span>
              </li>
              <li className="flex space-x-3">
                <span>🔄</span>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Continuous Integration & Deployment                 
                  <br/>
                  <span className="text-base font-medium leading-tight text-gray-500 dark:text-white">
                  Streamlining development processes for faster, more reliable releases
                  </span>
                </span>
              </li>
            </ul>
            <p className="mb-8 font-light lg:text-xl">
              Deliver great service experiences fast - without the complexity of
              traditional solutions.
            </p>
          </div>
          <img
            className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
            src="./images/feature-1.png"
            alt="dashboard feature"
          />
        </div>
        <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
          <img
            className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
            src="./images/feature-2.png"
            alt="feature"
          />
          <div className="text-gray-500 dark:text-gray-400 sm:text-lg">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              We invest in the world’s potential
            </h2>
            <p className="mb-8 font-light lg:text-xl">
              Deliver great service experiences fast - without the complexity of
              traditional ITSM solutions. Accelerate critical development work,
              eliminate toil, and deploy changes with ease.
            </p>
            <ul className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700">
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-purple-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Dynamic reports and dashboards
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-purple-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Templates for everyone
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-purple-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Development workflow
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-purple-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Limitless business automation
                </span>
              </li>
              <li className="flex space-x-3">
                <svg
                  className="h-5 w-5 flex-shrink-0 text-purple-500 dark:text-purple-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                  Knowledge management
                </span>
              </li>
            </ul>
            <p className="font-light lg:text-xl">
              Reduce operations cost of your business. So you can concentrate on
              what is really important/
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WeGotYou;
