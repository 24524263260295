import './App.css';
import Header from './components/Header';
import WeGotYou from './components/WeGotYou';
import TrustedBy from './components/TrustedBy';
import FAQSection from './components/FAQSection';
import Footer from './components/Footer';
import FeatureSectionLandingPage from './components/FeatureSectionLandingPage';
import TechPartners from './components/TechPartners';

function App() {
  return (
    <div className="App">
      <Header/>
      <FeatureSectionLandingPage/>
      <TechPartners/>
      <WeGotYou/>
      <TrustedBy/>
      <FAQSection/>
      <Footer/>
    </div>
  );
}

export default App;
